<template>
  <div>
    <template v-if="allowStatusShow">
      <template v-if="functionStatus">
        <span class="font-sm">
          <el-tag :type="functions[this.markFunction] === 'Cancelled' ? 'danger': 'success'" class="text-normalize">
            {{ functionLabel }}
          </el-tag>
        </span>
        <span class="font-xsm text-grey-tertiary marker">
          <span v-if="functionValue" class="sp">{{ functionValue }}</span> by
          {{ formatName(functionActor.name) }}
        </span>
      </template>
      <template v-else>
        <el-tag
          type="warning"
          :class="{ 'text-cursor': allowMarking }"
          @dblclick.native="allowMarking ? $emit('action') : false"
        >
          {{
            period === "today" ? "Pending" : `Not ${functions[markFunction]}`
          }}
        </el-tag>
      </template>
    </template>
    <span v-else class="font-sm">-</span>
  </div>
</template>

<script>
export default {
  name: "OrderFunctionStatus",
  props: {
    period: {
      type: String,
      default: "",
    },
    allowMarking: {
      type: Boolean,
      default: false,
    },
    allowStatusShow: {
      type: Boolean,
      default: true,
    },
    markFunction: {
      type: String,
      default: "",
    },
    functionStatus: {
      type: Boolean,
      default: false,
    },
    functionTime: {
      type: [String, Date],
      default: "",
    },
    functionValue: {
      type: String,
      default: "",
    },
    functionActor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      functions: {
        ready_operations: "Ready",
        ready_fpt: "Ready",
        in_transit: "In-transit",
        delivered_operations: "Delivered",
        cancelled_operations: "Cancelled",
        cancelled_fpt: "Cancelled",       
      },
    };
  },
  computed: {
    functionLabel() {
      return `${this.functions[this.markFunction]} at ${this.formatTime(
        this.functionTime,
        "12h"
      )}`;
    },
  },
  method: {
    action() {
      this.allowMarking ? this.$emit("action") : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  .marker {
    margin: 0 7px;
    font-weight: 400;

    .sp {
      font-weight: 600;
    }
  }
}
</style>
