<template>
  <div>
    <el-dialog title="Export MFC" :visible.sync="shouldShow" @close="closeEvent" width="30%">
      <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Proceed to select data format">
                <el-radio-group v-model="form.format">
                  <el-radio v-for="(key, value, i) in formats" :key="i" :label="value">
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button type="primary" :loading="loading || exporting" @click="exportFulfillment"
            :disabled="!form.format">Export</el-button>
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>
  
<script>
import fulfillment from "@/requests/fulfillment/index";
export default {
  name: "FulfillmentExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
    stringedFilter: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.format = "";
      this.shouldShow = false;
      this.exporting = false;
    },
    exportFulfillment() {
      this.mixpanelTrack({ event: "mfc_export" });
      if (!this.dataToExport.length) {
        this.loading = true;
        fulfillment
          .export(this.stringedFilter)
          .then((response) => {
            this.formatExportData(response.data.data);
            this.loading = false;
          })
          .catch((error) => {
            const errorMessage = error.response?.data.day;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
            this.loading = false;
          });
      } else {
        this.formatExportData(this.dataToExport)
      }
    },
    formatExportData(data) {
      console.log(data);
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `MFC \nExported at - ${date}`;
      let fulfillmentData = [];
      let headers = {
        sn: "S/N",
        order_id: "Order Id",
        customer_name: "Customer Name",
        customer_email: "Customer Email",
        customer_phone_no: "Customer Phone Number",
        order_date: "Order Date",
        items_assigned_to_order: "Meal"
      };
      fulfillmentData = data.map((fulfillment, index) => {
        console.log(fulfillment)
        return {
          sn: index + 1,
          order_id: fulfillment.order_id,
          customer_name: this.formatText(fulfillment.customer_name),
          customer_email: fulfillment?.customer_email,
          customer_phone_no: fulfillment.customer_phone_no,
          order_date: this.formatDate(fulfillment.order_date, "m do, y"),
          items_assigned_to_order: fulfillment.items_assigned_to_order.map(item => {
            return `${item.specifics} (${item.quantity})`;
          }).join(', '),
        };
      });

      this.exportData = {
        title,
        headers,
        data: fulfillmentData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
  